'use client'

import { AppBar } from '@patrianna/core-components'
import cx from 'classnames'
import Link from 'next/link'

import Button from 'components/Button'
import Page from 'components/Page'
import ROUTES from 'temp/routes.json'

interface GlobalErrorContentProps {
  onReload?: () => void
  title?: string
}

export default function GlobalErrorContent(props: GlobalErrorContentProps) {
  const { onReload, title } = props

  return (
    <>
      <AppBar className='global-error-page__header'>
        <Link href={ROUTES.INDEX}>
          <img
            src={`${process.env.IMG_ORIGIN}/logo-long.png`}
            height={32}
            alt={process.env.BRAND_NAME}
            className='global-error-page__header-logo'
          />
        </Link>
      </AppBar>
      <Page className='global-error-page__content' rootClassName='global-error-page__content-wrapper'>
        {/* All the texts on this page are hardcoded due to the impossibility of usage translations within this level */}
        {title && <h4 className='global-error-page__content-title'>{title}</h4>}

        <div>
          <Button
            variant='contained'
            className='global-error-page__content-btn'
            onClick={() => {
              document.location.href = ROUTES.INDEX
            }}
          >
            Back to Homepage
          </Button>

          {onReload && (
            <Button
              variant='outlined'
              className={cx('global-error-page__content-btn', 'global-error-page__content-btn--reload')}
              onClick={onReload}
            >
              Reload Page
            </Button>
          )}
        </div>
      </Page>
    </>
  )
}
